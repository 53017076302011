import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({ data, location }) => {
  return (
    <>
      <SEO title="the dormant office" />
    </>
  )
}

export default IndexPage
